  function isoToDate(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return ('00' + date.getDate()).slice(-2) + '.' + ('00' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
  }
  
  function isoToDateTime(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return ('00' + date.getDate()).slice(-2) + '.' + ('00' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
  }
  
  function isoToTime(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
  }
  
  function isoToMysqlDate(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2);
  }
  
  function isoToMysqlDateTime(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2) + ' ' + ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
  }
  
  function isoToMysqlTime(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
  }
  
  function inputDateTimeToIsoDateTime(inputDateTimeFormat) {
    return inputDateTimeFormat ? new Date(Date.parse(inputDateTimeFormat)).toISOString() : null
  }
  
  function isoDateTimeToInputDateTime(isoDateTimeFormat) {
    return isoToMysqlDateTime(isoDateTimeFormat).replace(" ", "T");
  }

  function getCreditStatusName(creditRequestStatus) {
    if(creditRequestStatus === 1){
      return 'Onay Bekliyor'
    } else if(creditRequestStatus === 2){
      return 'Onaylandı'
    } else if(creditRequestStatus === 3){
      return 'Reddedildi'
    } else if(creditRequestStatus === 4){
      return 'İptal Edildi'
    } 
    return '-'
  }
  
  module.exports = {isoToDate, isoToDateTime, isoToTime, isoToMysqlDate, isoToMysqlDateTime, isoToMysqlTime, inputDateTimeToIsoDateTime, isoDateTimeToInputDateTime, getCreditStatusName};